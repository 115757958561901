@keyframes bounce {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(100px);
    }
  }
  
  .bounce-animation {
    animation: bounce 1s ease-in-out;
  }

  @keyframes glow {
    0%, 100% {
      box-shadow: 0 0 5px rgba(255, 255, 0, 0.5), 
                  0 0 10px rgba(255, 255, 0, 0.5), 
                  0 0 15px rgba(255, 255, 0, 0.5), 
                  0 0 20px rgba(255, 255, 0, 0.5);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 255, 0, 1), 
                  0 0 30px rgba(255, 255, 0, 1), 
                  0 0 40px rgba(255, 255, 0, 1), 
                  0 0 50px rgba(255, 255, 0, 1);
    }
  }
  
  .glow-animation {
    animation: glow 1s ease-in-out infinite;
  }

  @keyframes grow {
    0%, 100% {
      transform: scale(1); /* Normal size */
    }
    50% {
      transform: scale(.8); /* Grow to 1.2x the size */
    }
  }
  
  .grow-animation {
    animation: grow 1s ease-in-out infinite;
  }
